export const colors = [
  'green',
  'blue',
  'red',
  'yellow',
  'purple',
  'mint',
  'grey',
  'note',
];
